<template>
  <div class="">
    <div class="">
      <v-card class="">
        <!-- logo -->
        <v-card-title class="d-flex py-7">
          <h2 class="text-2xl font-weight-semibold">
            Notifications
          </h2>
          <v-spacer>
          </v-spacer>
<!--          <v-btn
            color="primary"
            small
          >
            Marquer comme toutes lues
          </v-btn>-->
        </v-card-title>

        <v-card elevation="0" v-if="hnotification.listEntity.length == 0"  v-for="n in 6"
                :color="`grey darken-2 `"
                :key="n"
        >
          <v-skeleton-loader
            class="mx-auto"
            :height="72"
            type="list-item-avatar-two-line"
          ></v-skeleton-loader>
        </v-card>
        <!-- login form -->
        <v-card-text>
          <v-list three-line dense>
            <template v-for="(item, index) in hnotification.listEntity">
              <v-divider
                :key="'d-'+index"
              ></v-divider>

              <v-list-item class="ma-0 pa-0"
                :key="item.id"
                :to="redirect(item)"
                dense
              >
                <v-list-item-avatar class="" >
                  <v-img
                    v-if="item.avatar"
                    :src="require('@/assets/images/avatars/'+item.avatar)"
                  ></v-img>
                  <v-img
                    v-else
                    :src="require('@/assets/images/avatars/logo.png')"
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content class=" ma-0 pa-0 ">
                  <template v-if="item.notification">
                    {{ item.notification }}
                  </template>
                  <template v-else >
                    {{ item.content }}
                  </template>
                  <v-list-item-subtitle>
                    {{ item.created_at }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action v-if="item.entity !== 'follow'">
                  <img
                    class="mr-3"
                    width="50"
                    :src="env+'uploads/'+item.path+'/'+item.image"
                  >

                </v-list-item-action>
                <v-list-item-action v-else >
                  <img
                    class="mr-3"
                    width="50"
                    :src="require('@/assets/images/avatars/'+item.avatar)"
                  >
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>

        </v-card-text>
      </v-card>
    </div>

  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiClose,
  mdiArrowRight,
  mdiCreditCardOutline,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { Drequest } from '@/plugins/Drequest'
import { useRouter } from '@/utils'

export default {
  components: { },
  beforeRouteEnter(to, from, next) {
    console.log('beforeRouteEnter',to, from)
    // appelée avant que la route vers le composant soit confirmée.
    // cette fonction n'a pas accès à l'instance du composant avec `this`,
    // car le composant n'a pas encore été créé quand cette interception est appelée !
    next();
  },
  /*beforeRouteUpdate(to, from, next) {
    console.log('beforeRouteUpdate', to, from)
    // appelée quand la route qui fait le rendu de ce composant change,
    // mais que ce composant est utilisé de nouveau dans la nouvelle route.
    // Par exemple, pour une route avec le paramètre dynamique `/foo/:id`, quand nous
    // naviguons entre `/foo/1` et `/foo/2`, la même instance du composant `Foo`
    // va être réutilisée, et ce hook va être appelé quand cela arrivera.
    // ce hook a accès à l'instance de ce composant via `this`.
  },*/
  beforeRouteLeave(to, from, next) {
    console.log('beforeRouteLeave',to, from)
    // appelée quand la route qui fait le rendu de ce composant est sur le point
    // d'être laissée en faveur de la prochaine route.
    // elle a accès à l'instance de ce composant via `this`.
    if (from.path === '/')
      this.$router.push('/home')
    else
      next();
  },
  setup() {
    const user = Drequest.getUser()
    const env = Drequest.__env
    const subscription = ref({ })
    const hnotification = ref({listEntity:[]})
    const dialog = ref({
      newrefill: false,
      snack: false,
      confirm: false,
      loading: false,
    })

    const { router } = useRouter()
    const route = router.currentRoute
    if (route.params.option) dialog.value.newrefill = true

    const init = () => {
      Drequest.api('notification.list?dfilters=on&dsort=id desc&user_id:eq='+user.id)
        .get(response => {
          console.log(response)
          hnotification.value = response
        })
    }
    init()

    const refilled = () => {
      dialog.value.newrefill = false
      dialog.value.snack = true
      init()
    }
    const redirect = (item) => {
      if (item.redirect)
        return item.redirect
      if (item.entity === 'post')
        return '/activity/'+item.entityid

      return '/social'
    }

    return {
      init,
      redirect,
      refilled,
      hnotification,
      subscription,
      user,
      dialog,
      env,

      icons: {
        mdiClose,
        mdiEyeOutline,
        mdiArrowRight,
        mdiCreditCardOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
